import React from "react";
import SEO from "../../components/seo";
import Blog from "../../components/Blog";
import Par from "../../components/Par";
import H1 from "../../components/h1";

// Download link: http://www.fbrennangastro.com.au/downloads/gesa_bowel_polyps(1).pdf

const BowelCancer = () => {
	return (
		<Blog title="Bowel Polyps">
			<SEO
				title="Bowel Cancer Screening"
				keywords={[
					`Bowel polyps`,
					`Bowel Polyps treatment`,
					`Bowel Polyps prevention`,
					`Bowel Polyps symptoms`,
					`Bowel polyps and colonoscopies`,
					"Colonoscopy to remove bowel polyps",
				]}
				type="article"
				description="Bowel polyps - extra tissue in the bowels. How widespread is it, the symptoms of having them, how to test for it, treatments and prevention"
			/>
			<H1>Bowel Polyps</H1>
			<h2 className="mt-4">What are colonic polyps?</h2>
			<Par>
				A polyp is a extra tissue that may grow in many parts of the body eg nose, cervix or stomach. Colon polyps grow
				in the large intestine or colon. This is the long hollow tube at the end of your digestive tract where the body
				makes and stores stool.
			</Par>
			<h2 className="mt-4">Who gets colon polyps and are they dangerous?</h2>
			<Par>
				Colon polyps can be found in 30-50% of the population over 50. As only 4% of the population develop bowel cancer
				it is obvious that the majority of polyps are not dangerous and do not become cancerous. You are more likely to
				develop polyps as you get older or if someone in your family has had colon cancer or polyps. You are also more
				likely to develop polyps if you:
			</Par>
			<ul>
				<li className="mt-3">Eat a lot of fatty foods</li>
				<li className="mt-3">Smoke</li>
				<li className="mt-3">Drink alcohol</li>
				<li className="mt-3">Do not exercise</li>
				<li className="mt-3">Weigh too much</li>
			</ul>
			<Par>
				Large polyps (over 1.0cm in size) and those with a villous pattern under the microscope are more of a concern
				then smaller polyps or those with a tubular pattern under the microscope. Polyps seen to be hyperplastic under
				the microscope are of no concern except in rare circumstances which a specialist gastroenterologist will advise
				you of.
			</Par>
			<h2 className="mt-4">What are the symptoms?</h2>
			<Par>
				Most polyps do not cause symptoms and they are found during a routine check up. They can occasionally be a cause
				of rectal bleeding or diarrhoea. It is extremely rare for a polyp to block a bowel and cause constipation.
			</Par>
			<h2 className="mt-4">How do you test for polyps?</h2>
			<Par>There are several ways of testing for polyps:</Par>
			<ul>
				<li className="mt-3">
					A digital rectal exam may find polyps in the distal rectum and this method does not check for polyps in the
					more proximal bowel.
				</li>
				<li className="mt-3">
					A colonoscopy is where a flexible tube is passed thoughout the large bowel and is the most accurate way to
					detect polyps. Details of this procedure and what is involved can be found elsewhere on this website.
				</li>
				<li className="mt-3">
					A FOBT (faecal occult blood test) - a new method ie the immunochemical method is now available and involves
					having your stool checked for microscopic amounts of human blood. This is the method being used by the federal
					government to screen for bowel cancer. This is a very useful test for patients without symptoms, only 1 in 20
					positives will have a bowel cancer and if it is negative then you have a less than 1 in 100 chance of having
					bowel cancer at the time of the test.
				</li>
				<li className="mt-3">
					Other methods include doing a flexible sigmoidoscopy, barium enema or CT colography. Occasionally a
					combination of methods may be necessary to examine the entire colon or large bowel
				</li>
			</ul>
			<h2 className="mt-4">Who should get tested for polyps?</h2>
			<Par>
				If you have symptoms of bleeding, diarrhoea or constipation or if there is a family history of bowel cancer or
				polyps (and you are over 50) then you should consider a colonoscopy. This should be discussed with your doctor
				but if you have had a colonoscopy in the last 5 years then it may not need to be repeated.
			</Par>
			<Par>
				At present it is recommended that all patients over the age of 50 have an annual FOBT (faecal occult blood
				test), following this regime will reduce your risk from dying from bowel cancer by at least 30%.
			</Par>
			<Par>
				Alternatively you may consider having a colonoscopy every 10 years if you are at average risk (4%) of developing
				bowel cancer.
			</Par>
			<h2 className="mt-4">How are polyps treated?</h2>
			<Par>
				Polyps are removed at the time of colonoscopy if possible. This is usually extremely safe but may be complicated
				by bleeding or perforation of the bowel rarely. Very large polyps may require an operation which these days can
				be carried out laparoscopically in certain circumstances. Polyps are usually analysed in the laboratory and you
				may be advised to get tested regularly in the future.
			</Par>
			<h2 className="mt-4">How can I prevent polyps?</h2>
			<Par>There is no single way to prevent polyps but you may reduce your risk by:</Par>
			<ul>
				<li className="mt-3">Eating less fatty food and more fruit & vegetables</li>
				<li className="mt-3">Avoiding excess alcohol & cigarettes</li>
				<li className="mt-3">Exercise regularly and lose weight if you are overweight</li>
				<li className="mt-3">Eat more calcium and folate</li>
			</ul>
			<Par>
				Some doctors feel that aspirin may prevent polyps but there are risks with this medication that should be
				discussed with your doctor.
			</Par>
		</Blog>
	);
};

export default BowelCancer;
